import React from 'react';
import { BsTwitter, BsInstagram, BsLinkedin, BsGithub } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const socialLinks = [
  { id: 'linkedin', icon: <BsLinkedin />, href: 'https://www.linkedin.com/in/sanjayguwaju' },
  { id: 'github', icon: <BsGithub />, href: 'https://github.com/sanjayguwaju' },
  { id: 'twitter', icon: <BsTwitter />, href: 'https://twitter.com/sanjayguwaju', target: '_blank', rel: 'noopener noreferrer' },
  { id: 'facebook', icon: <FaFacebookF />, href: 'https://www.facebook.com/sanjayguwaju', target: '_blank', rel: 'noopener noreferrer' },
  { id: 'instagram', icon: <BsInstagram />, href: 'https://www.instagram.com/sanjayguwaju', target: '_blank', rel: 'noopener noreferrer' },
];

const SocialMedia = () => {
  return (
    <div className="app__social">
      {socialLinks.map(({ id, icon, href, target, rel }) => (
        <div key={id}>
          <a href={href} target={target} rel={rel}>
            {icon}
          </a>
        </div>
      ))}
    </div>
  );
};

export default SocialMedia;