import React from 'react';
import { NavigationDots, SocialMedia } from '../components';
import { getCurrentYear } from './../utils/dateUtils';

const AppWrap = (Component, idName, classNames) => function HOC() {
    const currentYear = getCurrentYear();
    return (
        <div id={idName} className={`app__container ${classNames}`}>
            <SocialMedia />
            <div className='app__wrapper app_flex'>
                <Component />
                <div className='copyright'>
                    <p className='p-text'>©{currentYear} SANJAY GUWAJU</p>
                    <p className='p-text'>All Rights Reserved</p>
                </div>
            </div>
            <NavigationDots active={idName} />
        </div>
    )
}

export default AppWrap