const NAV_ITEMS = ["home", "about", "work", "skills", "testimonials", "contact"];

const NavigationDots = ({ active }) => {
  console.log({active})
  return (
    <div className="app__navigation">
      {NAV_ITEMS.map((item, index) => (
        <a
        href={`#${item}`}
        key={item + index}
        className="app__navigation-dot"
        style={active === item ? { backgroundColor: "#313BAC" } : {}}
        >
         <span className="visually-hidden"></span>
        </a>
      ))}
    </div>
  );
};

export default NavigationDots;