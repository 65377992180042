import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

// Centralized Sanity configuration
const sanityConfig = {
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    dataset: 'production',
    apiVersion: '2022-02-01',
    useCdn: true,
    token: process.env.REACT_APP_SANITY_TOKEN,
};

export const client = createClient(sanityConfig);

const builder = imageUrlBuilder(client);

/**
 * Generates a URL for the given source image.
 * @param {Object} source The source image to generate a URL for.
 * @returns {string} The generated image URL.
 */
export const urlFor = (source) => builder.image(source);